import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/privacy-policy';

const PrivacyPoliciesRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../PrivacyPolicies')),
  name: 'PrivacyPolicies',
};

export default PrivacyPoliciesRoute;
