import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/liquidity/add';

const AddLiquidityRoute: IRouteProps = {
  path: route,
  exact: true,

  component: lazy(() => import('.')),
  name: 'AddLiquidity',
};

export default AddLiquidityRoute;
