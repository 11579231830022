import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/brand-assets';

const BrandAssetsRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../BrandAssets')),
  name: 'BrandAssets',
};

export default BrandAssetsRoute;
