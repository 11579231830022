import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/portfolio/detail';

const PortfolioDetailRoute: IRouteProps = {
  path: route,
  exact: true,

  component: lazy(() => import('.')),
  name: 'PortfolioDetail',
};

export default PortfolioDetailRoute;
