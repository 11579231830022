import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/portfolio';

const PortfolioRoute: IRouteProps = {
  path: route,
  exact: true,

  component: lazy(() => import('.')),
  name: 'Portfolio',
};

export default PortfolioRoute;
