import React from 'react';
import { Wrapper } from './Menu.styled';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

export const MenuForm = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Wrapper id="menu-modal-ov" className="menu-modal">
      <div
        className="overlay"
        onClick={() => {
          onMoveAnimation('menu-modal-ov', 'moveOutOpacity');
        }}
      ></div>
      <div className="setting-form">
        <div className="setting-title">
          <div className="title">Menu</div>
          <div
            className="btn-close"
            onClick={() => {
              onMoveAnimation('menu-modal-ov', 'moveOutOpacity');
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="border"></div>
        {[
          { name: 'Home', path: '/' },
          { name: 'Portfolio', path: '/portfolio' },
          { name: 'Swap', path: '/swap' },
          { name: 'Liquidity', path: '/liquidity' },
        ].map((item, key) => {
          return (
            <div
              key={key}
              className={activeTab === key ? 'menu-nav active' : 'menu-nav'}
              onClick={() => {
                history.push(`${item.path.toLowerCase()}`);
                setActiveTab(key);
                onMoveAnimation('menu-modal-ov', 'moveOutOpacity');
              }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default MenuForm;
